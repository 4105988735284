import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { FaFacebookF, FaTwitter, FaInstagram } from "react-icons/fa"
import axios from 'axios';
import { useState, useEffect } from 'react';
import $ from 'jquery';

import {ToastContainer, toast} from "react-toastify";
import Whatsapp from    "./whatsapp"
function Footer(props) {
 
    const handleOnClick = (e) => {

 
        if(window.location.hash){
            const hash = window.location.hash.replace("#",""); 
            window.location.hash = Math.random();
            if(document.getElementById(hash).offsetTop){
                var move_sc = document.getElementById(hash).offsetTop - 120;
                console.log(move_sc)
               setTimeout(function (){
                   window.scrollTo(0, move_sc)
               },500)
            } else{
                window.scrollTo(0, 0)
            }

        }

    };


    const [email, setEmail] = useState('');

    const handleSubmit = async  (e) => {
        e.preventDefault();
        var formData = new FormData();
        formData.append('request', JSON.stringify({
            email: email,
        }));

        var resp = await fetch(process.env.REACT_APP_API_URL+"/api/subscribe",{
            method: 'POST',
            body: formData
        });
        var data = await resp.json();

        if (data.success === true) {
            toast.success(data.msg);
            setEmail('');
        } else{
            toast.error(data.msg);
        }
      //  console.log('Email submitted:', email);
    };

    
    return (
        <>
            <footer className="footer-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-6 col-lg-3 about-footer mb-3">
                            <h4>ABOUT US</h4>
                            <p>Co-Founded by 3 close friends who seek to ‘Redefine ASEAN Diamond and Jewellery Industry’.</p>
                            <p>Build Locally, Expand Regionally, Impact Globally.</p>
                            <em><a href="/about-us">Learn More</a></em>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3 customer-service">
                            <h4>THE BAEMONT GUARANTEE</h4>
                            <ul>
                                <li><Link to="/contact-us#customer247"  onClick={handleOnClick}>20/7 Customer Service</Link></li>
                                <li><Link to="/contact-us#lifeTimeWarranty"  onClick={handleOnClick}>Product Lifetime Warranty</Link></li>
                                <li><Link to="/contact-us#lifetimeUpgrade"  onClick={handleOnClick}>Lifetime Upgrade</Link></li>
                                <li><Link to="/contact-us#freeShipping"  onClick={handleOnClick}>Free Shipping</Link></li>
                                <li><Link to="/contact-us#freeResizing"  onClick={handleOnClick}>Free Resizing</Link></li>
                                <li><Link to="/contact-us#freeEngraving"  onClick={handleOnClick}>Free Engraving</Link></li>
                                <li><Link to="/contact-us#priceMatch"  onClick={handleOnClick}>Price-Match Guarantee</Link></li>
                            </ul>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3 policies">
                            <h4>POLICIES</h4>
                            <ul>
                                <li><Link to="/privacy-policy" onClick={handleOnClick}>Privacy Policy</Link></li>

                                <li><Link  to="/faqs/#return-refund-cancellation" onClick={handleOnClick}  >Returns &amp; Refunds</Link></li>

                                <li><Link to="/faqs/#delivery" onClick={handleOnClick} >Shipping policy</Link></li>
                                <li><Link to="/baemont-terms-conditions" onClick={handleOnClick}>Terms &amp; Conditions</Link></li>
                                <li><Link to="/contact-us" onClick={handleOnClick}>Contact Us</Link></li>
                                <li><Link to="/faqs" onClick={handleOnClick}>Latest News</Link></li>
                            </ul>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3 follow-us">
                            <h4>FOLLOW US</h4>
                            <ul className="zoom-social-icons-list zoom-social-icons-list--with-canvas zoom-social-icons-list--rounded zoom-social-icons-list--no-labels">


                                <li className="zoom-social_icons-list__item">
                                    <a className="zoom-social_icons-list__link" href="https://facebook.com/baemontrings"
                                       target="_blank">

                                        <span className="screen-reader-text">facebook</span>

                                        <span className="zoom-social_icons-list-span social-icon socicon socicon-facebook" ></span>

                                    </a>
                                </li>


                                <li className="zoom-social_icons-list__item">
                                    <a className="zoom-social_icons-list__link" href="https://twitter.com/baemontrings"
                                       target="_blank">

                                        <span className="screen-reader-text">twitter</span>

                                        <span className="zoom-social_icons-list-span social-icon socicon socicon-twitter" ></span>

                                    </a>
                                </li>


                                <li className="zoom-social_icons-list__item">
                                    <a className="zoom-social_icons-list__link" href="https://instagram.com/baemontrings"
                                       target="_blank">

                                        <span className="screen-reader-text">instagram</span>

                                        <span className="zoom-social_icons-list-span social-icon socicon socicon-instagram" ></span>

                                    </a>
                                </li>


                                <li className="zoom-social_icons-list__item">
                                    <a className="zoom-social_icons-list__link" href="https://vt.tiktok.com/ZSGCaup/"
                                       target="_blank">

                                        <span className="screen-reader-text">tiktok</span>

                                        <span className="zoom-social_icons-list-span social-icon socicon socicon-tiktok" ></span>

                                    </a>
                                </li>


                                <li className="zoom-social_icons-list__item">
                                    <a className="zoom-social_icons-list__link"
                                       href="https://www.linkedin.com/company/baemontrings" target="_blank">

                                        <span className="screen-reader-text">linkedin</span>

                                        <span className="zoom-social_icons-list-span social-icon socicon socicon-linkedin" ></span>

                                    </a>
                                </li>


                            </ul>
                            <hr/>
                            <div className="subscribe-form"><p>Get the latest from BAEMONT! </p>

                                <form onSubmit={handleSubmit}>
                                    <input type="email" mame="email" className="form-control" value={email}
                                           onChange={(e) => setEmail(e.target.value)}
                                           required  placeholder="Your email address"/>
                                    <button type="submit" className="btn btn-dark d-block rounded-0 w-100 mt-2"  >Subscribe </button>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 footer-bottom">
                    <p>BAEMONT © 2020-{new Date().getFullYear()}. All rights reserved.</p>
                </div>
            </footer>
            <Whatsapp/>
        </>

  )
}

Footer.propTypes = {

}

export default Footer


