import React from "react";

const Whatsapp = () => {
    const phone = "+60103630381";
    const text = "Hello, I would like to know more about your services.";


    return (
        <a
            href={`https://api.whatsapp.com/send?phone=${phone}&text=${text}`}
            className="whatsapp-float"
            target="_blank"
            rel="noopener noreferrer"
        >
            <i className="fa-brands fa-whatsapp"></i>
        </a>
    );
};

export default Whatsapp;